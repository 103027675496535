import React, { useEffect } from "react";
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"; 
import ServiceAddress from "../new-pages/common/ServiceAddress";
import VideoSectionInternet from "../new-pages/common/VideoSectionInternet";
import HaveQuestion from "../new-pages/common/HaveQuestion";
import LastSection from "../new-pages/common/LastSection";
import MessageModal from "../new-pages/common/MessageModal";
import bubbleIcon from "../../../static/images/speech-bubble.png";
import Check from "../../static/images/checked-tick.svg";
import superIcon1 from "../../static/images/super-icon1.png";
import superIcon2 from "../../static/images/super-icon2.png";
import cowKissing from "../../static/images/cow-kissing.png";
import { calculateYearlyPrice } from "../../customfunction";
import RatingReviewInternet from "../new-pages/common/RatingReviewInternet";
import CommonQuestionWhenSettingUpYourTV from "../new-pages/common/CommonQuestionWhenSettingUpYourTV";
 

const TVSetupPage = () => {

    const [messagePopup, setMessagePopup] = React.useState(false);  


    return (
        <LayoutNew>
            <Helmet>
                <title>TV – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section1">
            <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">How to setup your TV</h2>
                </div>
            <div>
                <iframe width="1120" height="630" src="https://www.youtube.com/embed/9NAMjKYUhxU?si=WVrw36Ug_MJPa2gS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 1: Download the Purple Cow TV app</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                            Search Purple Cow TV and our app should show up. Feel free to click download. 
                     </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 2: Login with your credentials</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                        When you signed up for TV a username and password should have been texted to you. If you need this again or are having trouble finding it feel free to shoot us a text and we can resend it. 
                    </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 3: Enjoy your TV</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                        Purple Cow TV has all the features including recording live TV, storing in the cloud and then pausing and replay across all devices.


                    </p>
            </section>

            <CommonQuestionWhenSettingUpYourTV />

            <HaveQuestion closepopup={setMessagePopup}/>
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew >
    )
}

export default TVSetupPage