import React from "react";


import tvImg4 from "../../../../static/images/tv-4.png";

const ServiceAddress = ({closepopup}:any) => {
return (
    <>
        <section className="tv-section9">
            <div className="container mx-auto px-4">
                <h2 className="h2 text-secondary text-center">Still have a question?</h2>
                <div className="max-width1000">
                    <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="section9-text">
                            <h3 className="h3">Shoot us a message and our team will respond within mins</h3>
                            <p className="p2">Our main form of communication here at Purple Cow is through text message. We love this as it keeps things more personal and keeps history of the entire conversation.</p>
                            <button className="border-btn" type="button" onClick={() => closepopup(true)}>Message Us</button>
                        </div>
                        <div className="">
                            <img src={tvImg4} alt="TV" className="tv-sec9-img" />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </>
    )
}

export default ServiceAddress
