import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common setup questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    I can not find the Purple Cow TV app.
                                </AccordionHeader>
                                <AccordionBody>
                                Please make sure you are searching the app store in one of the below approved devices. If you are in a different device you might be in a different app store and we may not setup there yet. 
                                <p><strong>Apple TV</strong></p>
                                <li>tvOS 15 or later</li><br></br>
                                <strong>iOS Mobile</strong>
                                <li>iOS 15 or later</li><br></br>
                                <strong>Android Mobile</strong>
                                <li>7.0 or later</li><br></br>
                                <strong>Android TV</strong>
                                <li>Minimum OS supported is Android/Google TV 9.0 or higher (do not support android mobile release on STB)</li><br></br>
                                <strong>Google TV</strong>
                                <li>All versions</li><br></br>
                                <strong>Fire TV</strong>
                                <li>Fire TV (Gen 1) - AFTB (released April 12, 2014))</li>
                                <li>Fire TV Stick (Gen 1) - AFTM - (released Nov 19, 2014)</li>
                                <li>Fire TV (Gen 2) - AFTS - (released Sep 29, 2015)</li>
                                <li>Fire TV Stick (Gen 2) - AFTT (released Oct 20, 2016)</li>
                                <li>Fire TV Edition - Element 4K (2017) - AFTRS</li>
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    How do I record shows?  
                                </AccordionHeader>
                                <AccordionBody>
                                    To record shows you will need to add the DVR feature. Add DVR for $5 a month to record up to 50 hours of content that can be watched on any of your devices from any room. If you have just added the DVR feature and are not seeing it on your screen please login and out of your TV account.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    Can I add other channels?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yes, for sure. You can pretty much add anything you want to the Local News package. Also if you want to add a channel we currently do not have in our packages let us know and we will see if we can get it for you.                                
                                    </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    Can I see a complete list of channels Purple Cow offers and channel numbers?
                                </AccordionHeader>
                                <AccordionBody>
                                    <p>A complete channel guide: <a href="https://purplecowinternet.com/tv/channel-guide/" style={{color: "purple"}}>CHANNEL GUIDE</a></p>                                    
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
